<template>
  <div class="switcher">
    <div class="switcher__switch button_type_static button_color_transp-black" @click="$emit('switch')">
        <div class="switcher__content switcher__false">
            <button
                class="button button_type_static"
                :disabled="disabled"
                :class="{'button_color_black':!value}"
            >
               <slot name="false"></slot>
            </button>
        </div>
        <div class="switcher__content switcher__true">
            <button 
                class="button button_type_static"
                :disabled="disabled"
                :class="{'button_color_black':value}"
            >
                <slot name="true"></slot>
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'CustomSwitcher',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss">
.switcher {
    display: flex;
    &__switch {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        background: #FFFFFF;
        border: 1px solid #CBCCCF;
        border-radius: 6px;
        padding: 4px;

    }

    &__content {
        button {
            height: 32px;
            border-radius: 6px;
            padding: 6px 12px;
        }
    }
}
</style>