export default {
    state: {
        selected: false,
        paddingRight: '',
        pay: {
            value: false,
            isPin: true
        },
    },
    mutations: {
        SET_TOPBAR(state,data) {
            const stateItem = state[data.name]
            if(data.name == 'top' || data.name == 'selected') {
                stateItem = data.value    
            } else {
                stateItem.value = data.value
            }
        },
        SET_TOP_SELECTED(state,data) {
            state.selected = data
        },
        CLEAR_TOP_SELECTED(state) {
            state.selected = false
        },
        HIDEALL_TOPEDBAR(state) {
            for (const [key] of Object.entries(state)) {
                
                if(key == 'top' || key == 'selected' ) {
                    state[key] = false
                } else {
                    state[key].isPin ? state[key].value = false: ''
                }
            }
            
        },
        TOPBAR_SET_PADDINGRIGHT(state,data) {
            state.paddingRight = data
        }
    },
    actions: {
        TRIGGER_TOPBAR({commit,state},name) {
            commit('SET_TOPBAR',{
                name,
                value:!state[name].value
            })
        },
        SET_TOPBAR({commit},data) {
            commit('SET_TOPBAR',{
                name:data.name,
                value:data.value
            })
        },
        SELECT_TOPBAR({commit,state}, data) {
            commit('CLEAR_TOP_SELECTED')
            commit('HIDEALL_TOPEDBAR')
            commit('SET_TOP_SELECTED', data.selected)
            setTimeout(() => {
                commit('SET_TOPBAR',{
                    name:data.name,
                    value:!state[data.name].value
                })
            }, 1);
        },
        HIDEALL_TOPBAR({commit}) {
            commit('HIDEALL_TOPEDBAR')
        },
        RESET_TOPBAR({commit, state}) {
            commit('CLEAR_TOP_SELECTED')
            commit('HIDEALL_TOPEDBAR')
        },
        TOPBAR_SET_PADDINGRIGHT({commit},data) {
            commit('TOPBAR_SET_PADDINGRIGHT', data)
        }
        
    },
    getters: {
        topbarPay: state => state.pay,
        topbarSelected: state => state.selected,
        topbarPaddingRight: state => state.paddingRight
    }
}