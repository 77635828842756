import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            socket: null,
            status: null,
            errcounter: 0,
            minCountError: 5,
            maxCountError: 15,
            showsuccess: false,
            isErors: true
        }
    },
    methods: {
        initSocket() {
            const _this = this
            this.openSocket();
            this.socket.onopen = function(e) {
                const message = 'socket chat: Сокет успешно открыт'
                if(!_this.isErors) return
                //_this.$toast.success('Сокет успешно открыт')
                _this.isErors = false
            };
            
            this.socket.onmessage = function(event) {
                if(event.data ==  'Authenticated') return 
                let data
                try {
                    data = JSON.parse(event.data)
                } catch(e) {
                    //_this.$toast.error(e.message);
                    //_this.$toast.error(event.data);
                    console.error('socket chat: ',{e,event});
                    return
                }
                
                const { action } = data
                switch (action) {
                    case 'update_counter':
                        eventBus.$emit('socketUpdateCounter', data);
                        break;
                    case 'personal_message':
                        eventBus.$emit('socketMessage', data);
                        break;
                    case 'new_chat':
                        eventBus.$emit('socketNewСhat', data)
                        break;
                    case 'assign_end_message':
                        eventBus.$emit('socketAssignEndMessage', data);
                        break;
                    case 'close':
                        break;
                    case 'return_to_list':
                        break;
                    case 'assign_to_operator':
                        eventBus.$emit('socketRemoveChat', data)
                        break;
                    default:
                        break;
                }
            };
            this.socket.onclose = function(event) {
                const message = 'socket chat: Сокет закрыт, пытаемся открыть снова...'
                // if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                // }
                //_this.$toast.error(message, {e})
                let time = 1000 // Пауза в 1 секунду перед повторной попыткой открыть сокет
                if(!(_this.errcounter % _this.minCountError)) { 
                     // time  = 120000
                     time = 10000
                }
                setTimeout(() => {
                    _this.initSocket();
                })
                
            }
            this.socket.onerror = function(e) {
                const message = 'socket chat: Ошибка при открытии сокета, пытаемся открыть снова...'
                if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                    //_this.$toast.error(message)
                }
                if  (_this.errcounter == _this.maxCountError) {
                    this.showsuccess = true
                    //_this.$toast.error('Мы уведомим вас когда сокет будет работать')
                }
                _this.errcounter  += 1
                // console.error(message);
            }

            eventBus.$on('socketEmit', this.socketEmit);
        },
        // serttimer(){
        //     setTimeout(() => {
        //         this.socket.close()
        //         this.initSocket();
        //     }, 30000); 
        // },
        openSocket() {
            const token = this.user.token
            this.socket = null
            this.socket = new WebSocket('wss://new-8000.fleetsoft.pro?token='+token);
            if(this.errcounter > this.maxCountError && this.showsuccess) {
                this.errcounter  = 0
                this.showsuccess = false
                //this.$toast.success('Сокет сново открыт')
            }
            //this.serttimer()
        },
        socketEmit(data) {
            const body = JSON.stringify(data.data)
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket[data.method](body)
            } else {
                //this.$toast.error('WebSocket is already in CLOSING or CLOSED state.', {data});
                console.error('socket chat: WebSocket is already in CLOSING or CLOSED state.', {data})
            }
        }
    }
}