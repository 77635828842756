var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(()=>{_vm.isFocus=false}),expression:"()=>{isFocus=false}"}],staticClass:"custom-select",class:{
         'custom-select_type_error':_vm.error,
         'custom-select_type_focus':_vm.isFocus && !_vm.isload,
         'custom-select_type_disabled':_vm.disabled || _vm.isload
     }},[(_vm.label)?_c('label',{staticClass:"custom-select__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"custom-select__input",attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return (()=>{ _vm.isFocus=(!_vm.isFocus && !_vm.$props.disabled) }).apply(null, arguments)},"keydown":_vm.handleInputKeyUp}},[_c('div',{staticClass:"custom-select__input-selector",class:{
                 'custom-select__input-placeholder':!_vm.selected
             }},[(_vm.selected)?[_vm._v(" "+_vm._s(_vm.selected)+" ")]:[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]],2),(_vm.buttonIcon)?_c('button',{staticClass:"custom-select__button button_type_static button_type_icon",class:_vm.buttonIconClass,attrs:{"tabindex":"-1","type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClick($event)}}},[(_vm.buttonIcon)?_c('svg',{attrs:{"aria-hidden":"true","width":_vm.iconSize.w,"height":_vm.iconSize.h}},[_c('use',{attrs:{"href":_vm.buttonIcon}})]):_vm._e()]):_vm._e(),_c('v-button',{staticClass:"custom-select__button button_type_static",class:{'is-active':_vm.isFocus && !_vm.isload},attrs:{"tabindex":"-1","size":_vm.dropDownListr_button,"disabled":_vm.disabled,"loading":_vm.isload},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleVButtonClick.apply(null, arguments)}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"16","height":"16"}},[_c('use',{attrs:{"href":"#icon-arrow-down"}})])]),_c('v-list',{ref:"list",staticClass:"dropList_size_full-width",attrs:{"list":_vm.list,"expand":_vm.isFocus && !_vm.disabled && !_vm.isload,"selected":_vm.selectedItem,"keyprefix":_vm.keyprefix},on:{"click":function($event){return _vm.handleClickList($event)}}})],1),_c('transition',{attrs:{"name":"grow"}},[(_vm.error)?_c('div',{staticClass:"validation__label validation__label_type_error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }