import Janus from 'janus-gateway'
import adapter from 'webrtc-adapter'

export default {
    data( ) {
        return {
            // janus: null,
            plugin: null,
            audio: null,
        }
    },
    methods: {
        janusinit() {
            const {ip, port, id, password} = this.settings
            if(! ip || ! port || ! id || ! password) {
                console.error('janusinit: не все данные', {ip, port, id, password})
                return
            } else {    
                Janus.init({
                    dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
                    callback: () => {
                        this.connect(this.settings.wss_url)
                    }
                })
            }
        },
        
        // Метод для установления соединения с сервером Janus
        connect(server) {
            this.janus = new Janus({
                server: server,
                iceServers: null,
                success: () => {
                    this.attachPlugin()
                },
                error: (error) => {
                },
                destroyed: () => {
                    window.location.reload()
                }
            })
        },

        // Метод для прикрепления плагина "janus.plugin.streaming"
        attachPlugin() {
            this.janus.attach({
                plugin: "janus.plugin.sip",
                opaqueId: this.settings.opaqueId,
                success: (plugin) => {
                    this.plugin = plugin
                    const register = this.getRegister()
                    this.plugin.send(register);
                    //this.vjanusRegistering(register)
                },
                onmessage: (msg, jsep) => {
                    this.vjanMessage(this.plugin, {msg, jsep})
                },
                onremotetrack: (track, mid, on, meta) => {
                    if (track.kind === "audio") {
                        let stream = new MediaStream([track]);
                        this.$nextTick(() => {
                            this.audio = {stream, mid}
                            this.addtoAudioList(this.audio)
                            this.$nextTick(() => {
                                const _audio = this.$el.querySelector('#peervideom'+this.audio.mid)
                                Janus.attachMediaStream(_audio, stream);
                            })
                        })
                    }
                },
                onremotestream: (stream) => {
                    this.onRemoteStream(stream)
                },
                oncleanup: () => {
                },
                error: function (error) {
                    console.error(error)
                },
            })
        },

        getRegister() {
            const {ip, port, id, password} = this.settings
            const sipserver = "sip:"+id+"@" + ip + ":" + port;
            const username = "sip:"+id+"@" + ip;

            const register = {
                request: "register", username: username
            };
            register["secret"] = password;
            register["proxy"] = sipserver;
            return {message: register}
        },
        
    }
}