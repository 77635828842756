 
export default {
  props: {
    default: ''
  },
  data() {
    return {
      draggableContainer: ''
    }  
  },
  mounted () {
    this.draggableContainer = this.$refs.draggableContainer
  },
  methods: {
    handlePositionChange (e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      let prevY = e.clientY

      function mousemove (e) {
        
        const newX = prevX - e.clientX
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.left = rect.left - newX + 'px'
        draggableContainer.style.top = rect.top - newY + 'px'

        prevX = e.clientX
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeDown(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevY = e.clientY
      function mousemove (e) {
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.height = rect.height - newY + 'px'
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeUp(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevY = e.clientY
      function mousemove (e) {
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.height = rect.height + newY + 'px'
        draggableContainer.style.top = rect.top - newY + 'px'
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeLeft(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      function mousemove (e) {
        const newX = prevX - e.clientX

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.width = rect.width + newX + 'px'
        draggableContainer.style.left = rect.left - newX + 'px'
        prevX = e.clientX
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeRight(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      function mousemove (e) {
        const newX = prevX - e.clientX

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.width = rect.width - newX + 'px'
        prevX = e.clientX
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeTopLeft(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      let prevY = e.clientY
      function mousemove (e) {
        const newX = prevX - e.clientX
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.width = rect.width + newX + 'px'
        draggableContainer.style.left = rect.left - newX + 'px'

        draggableContainer.style.height = rect.height + newY + 'px'
        draggableContainer.style.top = rect.top - newY + 'px'

        prevX = e.clientX
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeBottomRight(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      let prevY = e.clientY
      function mousemove (e) {
        const newX = prevX - e.clientX
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.width = rect.width - newX + 'px'
        draggableContainer.style.height = rect.height - newY + 'px'
        
        prevX = e.clientX
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },
    
    handleResizeTopRight(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      let prevY = e.clientY
      function mousemove (e) {
        const newX = prevX - e.clientX
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()

        draggableContainer.style.width = rect.width - newX + 'px'
        draggableContainer.style.height = rect.height + newY + 'px'
        draggableContainer.style.top = rect.top - newY + 'px'

        prevX = e.clientX
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },

    handleResizeBottomLeft(e) {
      if(this.isPin) return
      let draggableContainer = this.draggableContainer

      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)

      let prevX = e.clientX
      let prevY = e.clientY
      function mousemove (e) {
        const newX = prevX - e.clientX
        const newY = prevY - e.clientY

        const rect = draggableContainer.getBoundingClientRect()
        draggableContainer.style.width = rect.width + newX + 'px'
        draggableContainer.style.left = rect.left - newX + 'px'
        draggableContainer.style.height = rect.height - newY + 'px'
        

        prevX = e.clientX
        prevY = e.clientY
      }
      function mouseup () { 
        window.removeEventListener('mousemove', mousemove)
        window.removeEventListener('mouseup', mouseup)
      }
    },
  }
};