import request from '../../helpers/request'

const DEFAULT_ENDPOINTS = {
    setNewPassword: '/auth/set-new-password',
    verifyForgotten: '/auth/verify-restore-code',
    forgotten: '/auth/request-restore-code',
    login: '/auth/login',
    confirmSms: '/verify/confirm',
    smsRepeat: '/verify/repeat-sms'
}

export default {
    forgotten: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.forgotten
        const { data } = await request.post(endpoint, payload)
        return data
    },
    verify: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.verifyForgotten
        const { data } = await request.post(endpoint, payload)
        return data
    },
    setNewPassword: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.setNewPassword
        const { data } = await request.post(endpoint, payload)
        return data
    },

    login: async({ email, password, url }) => {
        const endpoint = url || DEFAULT_ENDPOINTS.login
        const { data } = await request.post(endpoint, { email, password })
        return data
    },
    
    confirmSms:  async( payload, url, headers ) => {
        if(headers) {
            request.defaults.headers.common = headers
        }
        const endpoint = url || DEFAULT_ENDPOINTS.confirmSms
        const { data } = await request.post(endpoint, payload )
        return data
    },
    smsRepeat: async( payload, url, headers) => {
        if(headers) {
            request.defaults.headers.common = headers
        }
        
        const endpoint = url || DEFAULT_ENDPOINTS.smsRepeat
        const { data } = await request.post(endpoint, payload )
        return data
    }
}