import authRquest from '../helpers/authorizateRequest'

const DEFAULT_ENDPOINTS = {
    url: '/car/reference'
}

export default {
    get: async(_data,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.url
        const { data } = await authRquest.get( endpoint, _data)
        return data
    }
}