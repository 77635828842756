export default {
    state: {
        selected: false,
        pay: {
            value: false,
            isPin: true
        },
        trans: {
            value: false,
            isPin: true
        },
        search: {
            value: false,
            isPin: true
        },
        chat: {
            value: false,
            isPin: true
        },
        company: {
            value: false,
            isPin: true
        },
        top: 64
    },
    mutations: {
        SET_TOP_POSITION(state,data) {
            state.top = data
        },
        SET_SIDEBAR(state,data) {
            const stateItem = state[data.name]
            if(data.name == 'top' || data.name == 'selected') {
                stateItem = data.value    
            } else {
                stateItem.value = data.value
            }
        },
        SET_SELECTED(state,data) {
            state.selected = data
        },
        CLEAR_SELECTED(state) {
            state.selected = false
        },
        HIDEALL_SIDEDBAR(state) {
            for (const [key] of Object.entries(state)) {
                
                if(key == 'top' || key == 'selected' ) {
                    state[key] = false
                } else {
                    state[key].isPin ? state[key].value = false: ''
                }
            }
            
        },
        SET_PIN(state,data) {
            state[data.name].isPin = data.val
        }
    },
    actions: {
        TRIGGER_BAR({commit,state},name) {
            commit('SET_SIDEBAR',{
                name,
                value:!state[name].value
            })
        },
        SET_BAR({commit},data) {
            commit('SET_SIDEBAR',{
                name:data.name,
                value:data.value
            })
        },
        SELECT_BAR({commit,state}, data) {
            commit('SET_SELECTED', data.selected)
            commit('SET_SIDEBAR',{
                name:data.name,
                value:!state[data.name].value
            })
        },
        HIDEALL_BAR({commit}) {
            commit('HIDEALL_SIDEDBAR')
        },
        SET_TOP_POSITION({commit}, data) {
            commit('SET_TOP_POSITION', data)
        },
        RESET_SIDEBAR({commit, state}) {
            commit('CLEAR_SELECTED')
            commit('HIDEALL_SIDEDBAR')
        },
        SET_PIN({commit},data) {
            commit('SET_PIN',data)  
        }
    },
    getters: {
        barChat: state => state.chat,
        barSearch: state => state.search,
        barPay: state => state.pay,
        barTrans: state => state.trans,
        barCompany: state => state.company,
        barSelected: state => state.selected,
        barTopPosition: state => state.top,
        isBarOpen: state => state.search.value || state.pay.value || state.trans.value || state.chat.value || state.company.value
    }
}