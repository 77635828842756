import req from './request'

const DEFAULT_ENDPOINTS = {
    report: '/report/execute',
    get: '/report',
    downlaod: '/report/download',
    withdrawExport: '/payments/system/withdraw/export'
}

export default {
    downlaod(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.downlaod, param)
    },
    report(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.report, param)
    },
    get(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.get, param)
    },
    withdrawExport(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.withdrawExport, param)
    }
}