<template>
        <div class="dropList" :class="{
            'dropList_type_pull-top': !expand_up,
            'dropList_type_pull-bottom': expand_up,
            'dropList_type_active':true && isExpand
        }">
        <ul class="dropList__list">
            <li class="dropList__item" v-for="(item, index) in list"

                    :id="keyprefix + (item.id||item.text||item.name)"
                    :key="keyprefix + (item.id||item.text||item.name)"
                    :class="{
                        'dropList__item_type_disabled': item.selectable == false,
                        'dropList__item_type_selected': isEqual(selected, item),
                        'focuse': keyFocuse == index
                    }"
                    @click="item.route ? '' : handleClick(item)" 
                >
                <v-status v-if="type && type == 'status'" class="status_size_full-width" :text="item.text || item.name" :type="item.color" />
                <router-link v-else-if="item.route" :to="item.route">
                    {{''+(item.text || item.name) }}
                </router-link>
                <p v-else :class="item.class">
                    {{''+(item.text || item.name) }}
                </p>
                <button v-if="item.button" @click.stop="$emit('listButtonClick',item)" 
                    class="dropList__item-button button button_type_icon button_with_icon button_type_link"
                    :class="['button_color_'+item.button.color]"
                >
                    <svg aria-hidden="true" width="14" height="14">
                        <use :href="item.button.icon"></use>
                    </svg>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import vStatus from './Status'
export default {
    props: {
        list:{
            type: Array,
            default: () => []
        },
        expand: {
            type:Boolean,
            default:false,
        },
        type: {
            type:String,
            default: ''
        },
        selected: {
            type: [Object,Boolean],
            defaults: false
        },
        keyprefix: {
            type: String,
            default: ''
        }
    },
    components:{
        vStatus
    },
    data() {
        return {
            expand_up : false,
            keyFocuse: 0
        }
    },
    watch: {
        list() {
            this.$nextTick(()=>{ 
                this.keyFocuse = 0
            })
            
        }
    },
    computed:{
        isExpand() {
            let height = 0 ;

            if (this.expand && this.list.length) {
                const coord = this.getCoords(this.$el)
                if(!coord) return
                const documentBottom = document.documentElement.scrollHeight - 20
                height =  documentBottom - coord.top
                height = height>500? 500 : height
                
                if(documentBottom - coord.top < 250 ) {
                    this.expand_up = true
                    height = 500
                }
            }

            if (this.$el) { 
                this.$el.style.maxHeight=height+'px'
            }
        
            return this.expand
        }
    },
    methods: {
        handleClick(item) {
            if(item.selectable == false) return
            this.$emit('click',item)
        },
        getCoords(elem) {
            if(!elem) return
            let box = elem.getBoundingClientRect();

            return {
                top: box.top + window.pageYOffset,
                right: box.right + window.pageXOffset,
                bottom: box.bottom + window.pageYOffset,
                left: box.left + window.pageXOffset
            };
        },
        isEqual(val, item) {
            // Сравнение количества свойств объектов
            if (Object.keys(val).length !== Object.keys(item).length) {
                return false;
            }

            // Перебор свойств первого объект
            for (let prop in val) {
                // Проверка, есть ли такое же свойство во втором объекте
                if (item.hasOwnProperty(prop)) {
                // Сравнение значений свойств
                if (val[prop] !== item[prop]) {
                    return false;
                }
                } else {
                    return false;
                }
            }

            return true;
        },
        HandleUpArrow(){
            this.keyFocuse -=1
            this.keyFocuse = this.keyFocuse<0? this.list.length-1 : this.keyFocuse
            this.$nextTick(()=>{ this.updateActive() })
        },
        HandleUpDown(){
            this.keyFocuse +=1
            this.keyFocuse = this.keyFocuse>this.list.length-1? 0 : this.keyFocuse
            this.$nextTick(()=>{ this.updateActive() })
        },
        updateActive() {
            const conrrentFocuseEl = this.getCurrentFocuse()
            const listContainer = this.$el
            const itemBottom = conrrentFocuseEl.offsetTop + conrrentFocuseEl.clientHeight;
            const containerBottom = listContainer.clientHeight + listContainer.scrollTop;
            if(itemBottom > containerBottom) {
                listContainer.scrollTo({
                    top: conrrentFocuseEl.offsetTop,
                    behavior: "smooth",
                });
            } else if (listContainer.scrollTop > itemBottom) {
                listContainer.scrollTo({
                    top: conrrentFocuseEl.offsetTop,
                    behavior: "smooth",
                });
            }
        },
        getCurrentFocuse() {
            let ret = this.$el.querySelector('ul li.focuse')  

            if(!ret) {
                ret = this.getSelectedItem(this.selected.id)
            }
            return ret
        },
        hanldeKeyEnter(){
            
            if(this.keyFocuse < 0) {
                return null
            }
            const item =this.$el.querySelectorAll('ul li')[this.keyFocuse]
            if(!item) return
            item.click()    
        },
        getSelectedItem(id) {
            return this.$el.querySelector(`ul li.dropList__item_type_selected`)
        }

        
    },
}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_mixins';
$border-color:#CBCCCF;
.dropList {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    margin-top: 28px;
    border-radius: 6px;
    overflow: hidden;
    max-height: 0;
    box-shadow: 0 0 0 rgba(58, 65, 70, 0.06), 
                        0 0 0 rgba(58, 65, 70, 0.06);
    transition: .3s;
    visibility: hidden;

    &_type {
        &_active {
            visibility: visible;
            box-shadow: 0 4px 8px rgba(58, 65, 70, 0.06), 
                       0 8px 24px rgba(58, 65, 70, 0.06);
            overflow: auto;
            @include scrollbar();
        }

        &_pull-top{
            top: 100%;
        }
        &_pull-bottom{
            bottom: 100%;
            top: unset
        }
    }

    &_size {
        &_full-width{
            left: 0;
            right: 0;
        }
    }

    &__list {
        background: #fff;
        border: 1px solid $border-color;
        border-radius: 6px;
        overflow: hidden;
    }

    &__item {
        padding: 12px 16px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        
        &_type {
            &_disabled {
                pointer-events: none;
                color: $border-color;

                p{
                    pointer-events: none;
                }
            }

            &_selected { background: #d5d5d5; }
        }

        &:hover, &.focuse {
            background-color: #E7E7E8;
        }

        &:last-child {
            border-bottom: none;
        }

        p{
            cursor: pointer;
        }


    }
    &__item &__item-button {
        margin-left: auto;
    } 
}

</style>