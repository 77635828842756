var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel-list form"},[_c('div',{staticClass:"tel-list__item",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"tel-list__name"},[_vm._v(" "+_vm._s(_vm.timer)+" "),[_vm._v(" "+_vm._s(_vm.value.contact.name)+" ")],(_vm.value.contact.company)?_c('button',{staticClass:"button button_type_link button_type_static",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.handleClickToName(_vm.value.contact)}}},[_vm._v(" "+_vm._s(_vm.value.contact.name)+" "),_c('svg',{style:({marginLeft: '5px'}),attrs:{"aria-hidden":"true","width":"15","height":"15"}},[_c('use',{attrs:{"href":"#icon-link"}})]),(_vm.value.contact.company  &&  _vm.value.contact.company.company_name)?[_vm._v(" "+_vm._s(_vm.value.contact.company.company_name)+" ")]:_vm._e()],2):_vm._e()],2),_c('div',{staticClass:"tel-list__number",class:{'tel-list__number-centered': !_vm.value.contact.name}},[_vm._v(" "+_vm._s(_vm.value.contact.number)+" "),_c('div',{staticClass:"tel-list__buttons"},[_c('button',{staticClass:"button button_with_icon button_type_icon",class:{
                        'button_color_transp-yellow': _vm.showTransfer,
                        'button_color_transp-gray': !_vm.showTransfer
                },style:({marginRight: '24px'}),on:{"click":function($event){_vm.showTransfer = !_vm.showTransfer}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"9","height":"16"}},[_c('use',{attrs:{"href":"#icon-call-transfer"}})])]),_c('button',{staticClass:"button button_with_icon button_type_icon",class:{
                        'button_color_transp-black': !_vm.isMute,
                        'button_color_transp-gray': _vm.isMute
                },style:({marginRight: '24px'}),on:{"click":_vm.handleMuted}},[_c('svg',{attrs:{"aria-hidden":"true","width":"9","height":"16"}},[_c('use',{attrs:{"href":"#icon-microphone"}})])]),_c('button',{staticClass:"tel-list__button-red button button_with_icon button_type_icon button_color_transp-black",on:{"click":function($event){return _vm.$emit('action', {item:_vm.value, action: 'inchangup'})}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"30","height":"30"}},[_c('use',{attrs:{"href":"#icon-phone-denay-circle"}})])])])]),(_vm.showTransfer)?_c('div',{staticClass:"tel-list__number",style:({marginTop: '20px', alignItems: 'center'})},[_c('v-custom-input',{staticClass:"size_medium width__full",attrs:{"placeholder":"введите номер"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('button',{staticClass:"tel-wind__call button button_with_icon button_type_icon button_color_transp-black",style:({opacity: _vm.value.contact.transferring ? '.3' : '1'}),attrs:{"disabled":_vm.value.contact.transferring,"type":"submit"},on:{"click":_vm.handleTransfer}},[_c('svg',{attrs:{"aria-hidden":"true","width":"30","height":"30"}},[_c('use',{attrs:{"href":"#icon-transfer-circle"}})])])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }