
import req from './request'

const DEFAULT_ENDPOINTS = {
    total: '/transactions/summary/total',
    payments: '/transactions/summary/payments',
    gas: '/transactions/summary/gas',
}

export default {

    total(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.total, param)
    },

    payments(payload, param, method) { 
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payments, param)
    },

    gas(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.gas, param)
    },
}

