import api from '@api'
import { eventBus } from '@eventBus'

export default {
    data() {
        return {
            call_id: null,
        }
    },
    mounted() {
        eventBus.$on('candidateAssetCall', this.candidateAssetCall)
    },
    methods: {
        statisticSend(data) {
            if( data.event == "registered") return
            const action = data.event
            switch (action) {
                case 'calling':
                    this.sendOnTtel(data)
                break
                case 'incomingcall': 
                    this.sendOnTtel(data)
                break
                case 'transferring': 
                    data.agent_id = this.telProp.currentCall.contact.transferring
                    this.updateOnTel(data)
                break
                default :
                    // this.updateOnTel(data)
                break
            }
        },
        statisticMakeCall(data) {
           const payload = {
               customer: data.phoneNumber,
               call_id: data.msg.call_id
           }
           this.call_id = data.msg.call_id
           this.send(payload)
        },
        statisticHangup(data) {
            let payload = {}
            if(this.call_id && this.call_id == data.msg.call_id) {
                payload = {
                    call_id: this.call_id,
                    event: data.msg.result.reason,    
                }
                this.send(payload, 'put').then(res=>{
                    if(res && res.response) eventBus.$emit('endCall', res.response);
                })
                this.call_id = null
            }
        },
        sendOnTtel(data) {
            const event = data.event
            const call_id  = data.msg.call_id
            const customer = data.msg.result.username || data.phoneNumber
            const payload = {
                event,
                call_id: this.removeSettings(call_id),
                customer: this.removeSettings(customer)
            }
            if(data.prev_user) payload.prev_user = data.prev_user
            this.send(payload).then(res=>{
                if(res && res.response) {
                    eventBus.$emit('dumpID', res.response);
                }
            })
        },
        updateOnTel(data) {
            const payload = {
                event: data.event,
                call_id: this.removeSettings(data.msg.call_id),
            }

            if(data.agent_id) payload.agent_id = data.agent_id
            if(data.prev_user) payload.prev_user = data.prev_user

           
            this.send(payload,'put').then(res=>{
                // if(res.response && res.response.uuid) eventBus.$emit('receiveUUid', res.response.uuid);
                // if(res.response.isTransfer) this.transIncomingCall(res.response)
            });

        },
        removeSettings(str) {
            return str ? str.split('@')[0].replace('sip:','') : ''
        },
        send(payload, method) {
            return api.tel.telephonys(payload, '', method || 'post').then(res=>{
                // const {action, uuid} = res.response 
                // if(action == 'action_end_call') eventBus.$emit('endCall', {uuid});
                return res
            }).catch(error=>{
                this.$toast.error(error.message)
                console.error(error)
            })
        },
        assetCall(item) {
            const paylaod = {
                dump_id: item.dump_id,
                call_id: item.call_id
            }
            api.dumps.assetCall(paylaod,'','post').then(response=>{
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
            })
        },
        candidateAssetCall(data){
            const {contact, candidate_info} = data
            const payload = {
                candidate_id: candidate_info.id,
                call_id: contact.uuid
            }
            api.candidats.assetCall(payload,'','post').then(response=>{
            }).catch(error=>{
                this.$toast.error(error.message)
                console.error(error)
            })
        }
        

        
    }
}