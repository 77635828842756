var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isready)?_c('div',{staticClass:"chat-toggle"},[_c('button',{staticClass:"button button_size_small",class:{
        'button_color_black': _vm.selected == 'all',
    },attrs:{"disabled":_vm.disabled,"title":_vm.value.new},on:{"click":function($event){return _vm.$emit('click','all')}}},[_vm._v(" Все чаты "),(_vm.mes_new > 0 )?_c('span',{staticClass:"chat-toggle__counter"},[(_vm.mes_new <= 99)?[_vm._v(_vm._s(_vm.mes_new))]:[_vm._v(" 99+ ")]],2):_vm._e()]),_c('button',{staticClass:"button button_size_small",class:{
        'button_color_black': _vm.selected == 'my',
    },attrs:{"disabled":_vm.disabled,"title":_vm.mes_my},on:{"click":function($event){return _vm.$emit('click','my')}}},[_vm._v(" Мои чаты "),(_vm.mes_my > 0)?_c('span',{staticClass:"chat-toggle__counter"},[(_vm.mes_my <= 99)?[_vm._v(_vm._s(_vm.mes_my))]:[_vm._v(" 99+ ")]],2):_vm._e()]),_c('button',{staticClass:"button button_size_small",class:{
        'button_color_black': _vm.selected == 'history',
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click','history')}}},[_vm._v(" История ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }