import api from '@api'
export default {
    state: { 
        carStatusList: null,
        carBrands: null,
        carColor: null,
        carYears: null,
        carTransmissions: null,
        carAmenities: null,
        carCategories: null,
        carChairs: null
    },
    mutations: {
        car_refrences_success(state,data) {
            const { 
                status, 
                brands,
                colors,
                years,
                transmissions,
                amenities,
                categories,
                chairs
            } = data
            state.carStatusList = status
            state.carBrands = brands
            state.carColor = colors
            state.carYears = years
            state.carTransmissions = transmissions
            state.carAmenities = amenities
            state.carCategories = categories
            state.carChairs = chairs
        },
        RESET(state) {
            state.carStatusList = null
            state.carBrands = null
            state.carColor = null
            state.carYears = null
            state.carTransmissions = null
            state.carAmenities = null
            state.carCategories = null
            state.carChairs = null
        }
    },
    actions: {
        GET_CAR_REFRENCES({commit}){
            return new Promise( (resolve, reject) => {
                api.carReferences.get().then(res => {
                    commit('car_refrences_success', res)
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                    console.error(err)
                })
            })
        },
        RESET_CAR_REFRENCES({commit, state}) {
            commit('RESET')
        }
    },
    getters: {
        carStatusList: state => state.carStatusList,
        carBrands: state => state.carBrands,
        carColor: state => state.color,
        carYears: state => state.carYears,
        carTransmissions: state => state.carTransmissions,
        carAmenities: state => state.carAmenities,
        carCategories: state => state.carCategories,
        carChairs: state => state.carChairs
    }
}