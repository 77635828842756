import req from './request'

const DEFAULT_ENDPOINTS = {
    translations:'/translations',
}

export default {
    translations(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.translations, param)
    },
}