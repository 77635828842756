<template>
    <div class="tel" v-if="telProp.JanysReady">
        <button class="tel-button" v-if="!showWind" @click="showWind = true"
            :class="{'tel-button_active': telProp.isCall || telProp.calling }"
        >
            <div class="tel-button__counter" v-if="telProp.callList.length && !telProp.telInput.timer">
               {{ telProp.callList.length }}
            </div>
            <div class="tel-button__timer" v-if="telProp.telInput.timer">{{ telProp.telInput.timer }}</div>
            <svg aria-hidden="true" width="24" height="24">
                <use href="#icon-phone-2"></use>
            </svg>
        </button>
        
        <v-tel-wind 
            @action="handleAction"
            @close="showWind = false" 
            :agentName="agentName" 
            v-model="telProp" 
            ref ="telWind" v-else 
            @dtmf="handleDTMF"
        />
        <template v-for="audio in audioList">
            <audio :id="'peervideom' + audio.mid" autoplay playsinline />
        </template>
    </div>
</template>

<script>
    import vTelWind from './modules/TelWind'
    import mixJanus from './mixin/vJan'
    import vPreloader from '@components/global/Preloader'
    import telStatistic from './mixin/telStatistic'
    import browserTabs from '@mixin/browserTabs'
    export default {
        name: 'Tel',
        mixins: [mixJanus, telStatistic, browserTabs],
        components: {
            vTelWind,
            vPreloader,
        },
        data() {
            return {
                showWind: false,
                isJanus: false,
            }
        },
        mounted() {
            
           
        },
        methods: {
            handleMakeCall(e) {
                const {phoneNumber} = e
                this.telProp.telInput.phoneNumber = phoneNumber
                const dump_id = e.dump_id
                this.handleAction({phoneNumber, dump_id, action:'tab_call'})

            },
            handleAction(event) {
                const action = event.action
                switch(action) {
                    case 'call':
                        this.vJanusMakeCall(event.phoneNumber)
                        break;
                    case 'accept':
                        this.vJanusAcceptIncoming(event.item)
                        break;
                    case 'inchangup':
                        this.vJanusHangupCall(event.item)
                        break;
                    // case 'hangup':
                    //     this.vjanusHangup(event.item)
                    //     break;
                    case 'transfer':
                        this.vJanusTransferCall(event.item)
                        break;
                    case 'muteSwitch': 
                        this.vJanusMuteAudio()
                        break;
                    case 'tab_call':
                        this.vJanusTabCall(event)
                    break;
                }
            },
            handleDTMF(event) {
              this.vJanusDTMF(event)
            }
        }
    }
</script>

<style lang="scss">
    .tel {
        position: fixed;
        bottom: 20px;
        left: 71px;
        z-index: 1000;
        @media (min-width: 1500px) {
            left: 103px;
        }
    }
    .tel-button {
        
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-color: #fff;
        box-shadow: 0px 4px 12px rgba(58, 65, 70, 0.12), 0px 2px 4px rgba(58, 65, 70, 0.12);
        border-radius: 50%;

        &__counter,&__timer { 
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 30px;
            min-width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #E94A4A;
        }

        &__timer { 
            width: auto;
            right: inherit;
            left: 100%;
            margin-left: -25px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &_active {
            background-color: #30C66C;
            color: #fff;
            
        }
        svg {
            fill: currentColor;
        }
    }
</style>