<template>
    <div class="navbar" @click="expand=!expand" v-click-outside="()=>{this.expand = false}" >
        <v-nav-logo :expand="expand"/>
        <transition name="expand">
            <v-nav-search :expand="expand" v-model="search" />
        </transition>

        <v-nav-menu v-if="user" 
            :expand="expand"
            :search="search"
            :menu="user.menu"
            @routeClick="handleRouteClick"
            @submenuClick="()=>{this.expand = true}"/>
    </div>
</template>

<script>
import vNavMenu from './modules/NavMenu.vue'
import vNavLogo from './modules/NavLogo.vue'
import vNavSearch from './modules/vNavSearch.vue'

import { mapState } from 'vuex'

export default {
    name:'vNavbar',
    computed: {
        ...mapState({
            // menu: state => state.profile.menu
            user: state => state.profile.user 
        })
    },
    components:{
        vNavMenu,
        vNavLogo,
        vNavSearch,
    },
    data() {
        return {
            expand:false,
            search: ''
        }
    }, 
    methods: {
        handleRouteClick() {
            this.search =""
            this.expand = false
        },
    }
}
</script>

<style lang="scss">
    @import '../../../../gulp-src/app/scss/variables';
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 101;
        display: flex;
        flex-direction: column;
        background-color: $global-dark-3a;

        @media (min-width: 1500px) {
            padding: 0 16px;
        }
    }
    
    .expand-enter-active, .expand-leave-active {
        transition: .5s;
    }
    .expand-enter, .expand-leave-to {
        max-width: 0;
        opacity: 0;
        
    }
</style>
