import Vue from 'vue'
import Axios from 'axios'
import vClickOutside from 'v-click-outside'
import Toast from "vue-toastification";


// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import 'floating-vue/dist/style.css'

import Inputmask from 'inputmask'
const VueInputMask = {
  install: function(Vue, options) {
      Vue.directive('mask', {
          bind: function(el, binding) {
              Inputmask(binding.value).mask(el);
          }
      });
  }
}

const toastOptions = {
    timeout: 2000, // Установите желаемое время отображения в миллисекундах (в данном случае 3 секунды).
    // Другие параметры опций могут идти здесь
  };

Vue.use(vClickOutside)
Vue.use(VueInputMask)
Vue.use(Toast,toastOptions)

Vue.prototype.$http = Axios;