var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropList",class:{
        'dropList_type_pull-top': !_vm.expand_up,
        'dropList_type_pull-bottom': _vm.expand_up,
        'dropList_type_active':true && _vm.isExpand
    }},[_c('ul',{staticClass:"dropList__list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:_vm.keyprefix + (item.id||item.text||item.name),staticClass:"dropList__item",class:{
                    'dropList__item_type_disabled': item.selectable == false,
                    'dropList__item_type_selected': _vm.isEqual(_vm.selected, item),
                    'focuse': _vm.keyFocuse == index
                },attrs:{"id":_vm.keyprefix + (item.id||item.text||item.name)},on:{"click":function($event){item.route ? '' : _vm.handleClick(item)}}},[(_vm.type && _vm.type == 'status')?_c('v-status',{staticClass:"status_size_full-width",attrs:{"text":item.text || item.name,"type":item.color}}):(item.route)?_c('router-link',{attrs:{"to":item.route}},[_vm._v(" "+_vm._s(''+(item.text || item.name))+" ")]):_c('p',{class:item.class},[_vm._v(" "+_vm._s(''+(item.text || item.name))+" ")]),(item.button)?_c('button',{staticClass:"dropList__item-button button button_type_icon button_with_icon button_type_link",class:['button_color_'+item.button.color],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('listButtonClick',item)}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"14","height":"14"}},[_c('use',{attrs:{"href":item.button.icon}})])]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }