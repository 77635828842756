import Vue from 'vue'

import vHeader from './header/Header'
import vNavbar from './Navigation/Navigation'
import vSidebar from './Sidebar/vSidebar'

// const vHeader = () => import( './header/Header')
// const vNavbar = () => import( './Navigation/Navigation')
// const vSidebar = () => import( './Sidebar/vSidebar')

const globalComponents = [
  vHeader,
  vNavbar,
  vSidebar
]

globalComponents.forEach(component => Vue.component(component.name, component))