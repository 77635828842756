import api from '../../api'
export default {
    state: {
        activity_list: null,
        city_list: null ,
        department_list: null,
        operator_online_status: null ,
        park_platform: null,
        source_withdraw_type: null,
        transactions_categories_list: null,
        work_rule_type_list: null,
        type_account_list: null
    },
    mutations: {
        refrences_success(state,data) {
            state.activity_list = data.activity_list
            state.city_list = data.city_list
            state.department_list = data.department_list
            state.operator_online_status = data.operator_online_status
            state.park_platform = data.park_platform
            state.source_withdraw_type = data.source_withdraw_type
            state.transactions_categories_list = data.transactions_categories_list
            state.work_rule_type_list = data.work_rule_type_list
            state.type_account_list = data.type_account_list
        },
        RESET(state) {
            state.activity_list = null
            state.city_list = null
            state.department_list = null
            state.operator_online_status = null
            state.park_platform = null
            state.source_withdraw_type = null
            state.transactions_categories_list = null
            state.work_rule_type_list = null
        }
    },
    actions: {
        GET_REFRENCES({commit}) {
            return new Promise( (resolve, reject) => {
                
                api.references.get().then(res => {
                    commit('refrences_success', res)
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                })
            })
        },
        RFRESH_REFRENCES({ dispatch, commit, state }) {
            // Вызываем GET_REFRENCES
            return dispatch('GET_REFRENCES')
                .then(res => {
                    // Если запрос успешен, данные уже будут обновлены в состоянии
                    // Вы можете сделать что-то еще здесь, если нужно
                    return res; // Возвращаем данные, если это необходимо
                })
                .catch(err => {
                    // Обработка ошибки, если GET_REFRENCES не удалось выполнить
                    console.error('Ошибка при обновлении ссылок:', err);
                    throw err; // Пробрасываем ошибку дальше
                });
        },
        
        RESET_REFRENCES({commit, state}) {
            commit('RESET')
        }
    },
    getters: {
        activity_list: state => state.activity_list,
        city_list: state => state.city_list,
        department_list: state => state.department_list,
        operator_online_status : state => state.operator_online_status,
        park_platform : state => state.park_platform,
        source_withdraw_type: state => state.source_withdraw_type,
        transactions_categories_list : state => state.transactions_categories_list,
        work_rule_type_list : state => state.work_rule_type_list,
        type_account_list: state => state.type_account_list
    }
}