<template>
  <div class="status" :class="['status_color_'+type, 'status_size_'+size]">
    {{text}}
  </div>
</template>

<script>
export default {
    props:{
        text: {
            type:String,
            default:''
        },
        type: {
            type:String,
            default:''
        },
        size: {
            type: String,
            default: ''
        }
    }
}
</script>

 