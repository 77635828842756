import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            tel_socket: null,
            status: null,
            errcounter: 0,
            minCountError: 5,
            maxCountError: 15,
            showsuccess: false,
            isErors: true
        }
    },
    methods: {
        tel_initSocket() {
            const _this = this
            this.tel_openSocket();
            this.socket.onopen = function(e) {
                if(!_this.isErors) return
                //_this.$toast.success('Сокет телефонии успешно открыт')
                _this.isErors = false
            };
            
            this.tel_socket.onmessage = function(event) {
                if(event.data ==  'Authenticated') return 
                let data
                try {
                    data = JSON.parse(event.data)
                } catch(e) {
                    //_this.$toast.error(e.message);
                    //_this.$toast.error(event.data);
                    //console.error({e,event});
                    return
                }
                
                const { action } = data
                switch (action) {
                    case 'action_incoming_call':
                        eventBus.$emit('incomingCall', data);
                        break;
                    case 'action_end_call':
                        eventBus.$emit('endCall', data);
                        break;
                    // case 'create_call':
                    //     const {uuid} = data
                    //      eventBus.$emit('endCall', {uuid});
                    //     break;
                    default:
                        break;
                }
            };
            this.tel_socket.onclose = function(e) {
                const message = 'socket telephoniya: Сокет телефонии закрыт, пытаемся открыть снова...'
                this.isErors = true
                // if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                // }
                //_this.$toast.error(message, {e})
                let time = 1000 // Пауза в 1 секунду перед повторной попыткой открыть сокет
                if(!(_this.errcounter % _this.minCountError)) { 
                    // time  = 120000
                    time = 10000
                }
                setTimeout(() => {
                    _this.tel_initSocket();
                }, time);
            }
            this.tel_socket.onerror = function(e) {
                const message = 'socket telephoniya: Ошибка при открытии сокета телефонии, пытаемся открыть снова...'
                if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                    //_this.$toast.error(message)
                }
                if  (_this.errcounter == _this.maxCountError) {
                    this.showsuccess = true
                    //_this.$toast.error('Мы уведомим вас когда сокет телефонии будет работать')
                }
                _this.errcounter  += 1
                //console.error(message);
            }

            

            eventBus.$on('socketEmit_tel', this.tel_socketEmit);
        },
        // tel_settimer() {
        //     setTimeout(() => {
        //         this.tel_socket.close()
        //         this.tel_initSocket();
        //     }, 30000); 
        // },
        tel_openSocket() {
            const token = this.user.token
            const url = 'wss://new-8003.fleetsoft.pro?token='+token
            this.tel_socket = null
            this.tel_socket = new WebSocket(url);
            if(this.errcounter > this.maxCountError && this.showsuccess) {
                this.errcounter  = 0
                this.showsuccess = false
                //this.$toast.success('Сокет телефонии сново открыт')
            }
            //this.tel_settimer()
        },
        tel_socketEmit(data) {
            const body = JSON.stringify(data.data)
            if (this.tel_socket.readyState === WebSocket.OPEN) {
                this.tel_socket[data.method](body)
            } else {
                //this.$toast.error('WebSocket is already in CLOSING or CLOSED state.', {data});
                // console.error('socket telephoniya: WebSocket is already in CLOSING or CLOSED state.', {data})
            }
        }
    }
}