<template>
  <ul class="bill-status" :class="{['bill-status_size_'+size]:!!size}">
    <li class="bill-status__item" 
        :class="['bill-status__item_color_'+status.type]"
        v-for="status in value"
        :key="status.id"
        @click="handlChangeStatus"
    >
            <router-link
                :to="{name: 'bill', query: {status: statusList[status.type]}}"
                class="bill-status__label"
                :class="['bill-status__label_color_'+status.type]"
            >
                {{status.value}}
        </router-link>
    </li>
  </ul>
</template>

<script>
import { eventBus } from '@eventBus'
export default {
    props:{
        value:{
            type: Array,
            default: () => []
        },
        size: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            statusList:{
                success: 1,
                danger: 2,
                warning: 3 
            }
        }
    },
    methods: {
        handlChangeStatus() {
            eventBus.$emit('changeStatus')
        }
    }

}
</script>

<style lang="scss">
    .bill-status {
        display: flex;
        margin-right: auto;

        &__item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-right: 10px;

            @media (min-width: 1500px) {
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0;
            }
            &_color{
                &_success {
                    color: #30C66C;
                }
                &_warning {
                    color: #FF9C11;
                }
                &_danger {
                    color: #E94A4A;
                }
            }
        }
        &__label {
            display: flex;
            align-items: center;
            padding: 8px;
            margin-left: 8px;
            //width: 26px;
            width: fit-content;
            min-width: 26px;
            height: 26px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 14px;
            background: #000;
            color: #fff;
            justify-content: center;

            &_color{
                &_success {
                    background-color: #30C66C;
                }
                &_warning {
                    background-color: #FF9C11;
                }
                &_danger {
                    background-color: #E94A4A;
                }
            }
        }

        &_size_small &{
            &__item {
                font-size: 0;
            }
        }
    }
</style>