import req from './request'

const DEFAULT_ENDPOINTS = {
    sms: '/communications/sms',
}

export default {
    sms(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.sms, param)
    },
}

