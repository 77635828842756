import req from './request'

const DEFAULT_ENDPOINTS = {
    noComplete: '/dumpsLead/marketing/no-complete',
    Complete: '/dumpsLead/marketing/complete',
    Statistic: '/dumps/marketing/statistic',
    calling: '/dumpsLead/calling',
    driver: '/dumpsLead/driver-info/general',
    promotion: '/dumpsLead/driver-info/promotions',
    toWork: '/dumpsLead/driver-info/to-work',
    comments: '/dumpsLead/driver-info/comments',
    addcomment: '/dumpsLead/driver-info/add-comment',
    assetCall: '/dumpsLead/asset-call'
}

export default {
    noComplete(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.noComplete, param)
    },
    complete(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.Complete, param)
    },
    statistic(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.Statistic, param)
    },
    calling(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calling, param)
    },
    driver(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driver, param)
    },
    promotion(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promotion, param)
    },
    toWork(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.toWork, param)
    },
    comments(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.comments, param)
    },
    addcomment(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.addcomment, param)        
    },
    assetCall(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.assetCall, param)
    }
}