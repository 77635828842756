export default class Timer {
    constructor(options) {
        this.settings = {
            ...this.defaults,
            ...options
        }
    }

    get defaults() {
        return {
            affterUpdate: null,
            seconds: 0,
            textContent: '',
            timer: null
        }
    }

    get textContent () {
        return this.settings.textContent
    }

    set textContent (value) {
        this.settings.textContent = value
    }
    
    start() {
        this.settings.timer = setInterval(this.updateTimer.bind(this), 1000);
    }
    
    stop() {
        clearInterval(this.settings.timer);
        this.settings.timer = null
        this.settings.textContent = ''
        this.settings.seconds = 0
        this.settings.affterUpdate(this.settings)
    }
    updateTimer(){
        let seconds = this.settings.seconds
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        // Форматирование времени в формат HH:MM:SS
        let formattedTime = '';

        
        if (hours > 0) {
            formattedTime = `${String(hours).padStart(2, '0')}:`;
        }

        formattedTime += `${String(minutes)}:${String(remainingSeconds).padStart(2, '0')}`;
    
        // Обновление текста таймера
        this.settings.textContent = formattedTime
        this.settings.affterUpdate(this.settings)
    
        // Увеличение количества секунд
        this.settings.seconds++;
    }    
}