<template>
     <div class="fast-category"
        ref="draggableContainer"
        :class="{'is-loading':isLoading, 
            'fast-category__draggable': !this.$store.getters.barChat.isPin
        }"
     >
        
        <div class="draggable__border draggable__border-left" @mousedown.stop="handleResizeLeft($event)"></div>
        
        <div class="fast-category__cont">
            <v-preloader v-if="isLoading" />
            <v-fast-phrase :list="phrases" v-if="phrases.length" @click="$emit('phraseClick',$event)" />
            <template>
                <div class="fast-category__list" >
                    <div class="fast-category__item" v-for="item in fastList" :key="item.id">
                        <v-button 
                            :size="'full-width'"
                            class="fast-category__button"
                            :class="{'active': activeId == item.id}"
                            @click="handleItemClick(item)"
                            :loading="itemload"
                            :disabled="itemload"
                        >
                            <img v-if="item.icon" :src="item.icon" class="img-icon"> 
                            {{ item.name }}
                            <svg width="16" height="16" class="svg_arrow" v-if="!itemload">
                                <use href="#icon-arrow-right"></use>
                            </svg>
                        </v-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import mixinDraggable from '@components/global/draggable.js'
import vButton from '../../global/vButton.vue';
import vPreloader from '../../global/Preloader';
import { mapState, mapActions } from 'vuex'
import api from '@api'
import vFastPhrase from './fastPhrase';


export default {
    components:{
        vButton,
        vPreloader,
        vFastPhrase
    },
    computed:{
        ...mapState({
            fastList: state => state.chat.fastphrase,
            barChat: state => state.chat,
        }),
    },
    props: {
        chat_id: {
            type: [Number,String],
            default: ''
        }
    },
    mixins: [mixinDraggable],
    data() {
        return {
            isLoading: true,
            activeId: null,
            itemload: false,
            phrases: []
        }
    },
    beforeDestroy() {
        this.RESET_CHATS()
    },
    mounted() {
        this.GET_CHAT_FASTPHRASE({id:this.chat_id}).catch(error=>{
            this.$toast.error(error.message)
            console.error(error)
        }).finally(e=>{
            this.isLoading = false
        })
    }, 
    methods: {
        ...mapActions([
            'GET_CHAT_FASTPHRASE',
            'RESET_CHATS'
        ]), 
        handleItemClick(item){
            if(this.activeId == item.id) {
                this.activeId = null
                this.phrases = false
                return
            }
            this.activeId = item.id
            const payload = {
                id: item.id,
                is_template: item.is_template || false
            }
            this.itemload = true
            this.getPhrases(payload,'','post').then(res=>{
                this.phrases = res.response
            }).finally(e=>{
                this.itemload = false
            })

            //POST /communications/chat/quick-phrases
            //payload: {id: int, is_template: bool}

        },
        getPhrases(paylaod,param,method){
            return api.chat.quickPhrase(paylaod,param,method).then(response=>{
                return response
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        }
    }
}
</script>



<style lang="scss">
@import '../../../../../gulp-src/app/scss/_variables';
@import '../../../../../gulp-src/app/scss/_mixins';

    .fast-category{
        position: absolute;
        top: -32px;
        bottom: -32px;
        right: 100%;
        margin-right: 16px;
        width: 420px;
        background-color: #fff;
        box-shadow: -4px 0px 12px 0px rgba(58, 65, 70, 0.06), -20px 10px 16px 0px rgba(58, 65, 70, 0.12);
        left: initial!important;

        &__cont {
            padding: 32px 24px;
            height: 100%;
        }
        &.is-loading {
            display: flex;
            justify-content: center;
            align-items: center;    
        }
        &__draggable {
            border: 1px solid #CBCCCF;
            box-shadow: 0px 4px 8px rgba(58, 65, 70, 0.06), 0px 8px 24px rgba(58, 65, 70, 0.06);
            border-radius: 16px;
        }
        &__list {
            height: 100%;
            @include scrollbar($thumb-color:$global-dark-3a, $track-color:$global-gray);
            padding-right: 10px;
            margin-right: -10px;
        }
        & &__button {

            color: $global-dark-3a;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            border-radius: 6px;
            border: 1px solid $global-gray;
            padding: 16px;
            margin-bottom: 20px;
            display: flex;
            justify-content: inherit;
            span {
                display:flex;
                width: 100%;
            }
            // display: flex;
            // cursor: pointer;
            // &:last-child {
            //     margin-bottom: 0;
            // }
            &.active{
                background: #3a4146;
                color: #fff;
            }
        }
        .img-icon{
            margin-right: 12px;
            width: 20px;
            height: 20px;
        }
        .svg_arrow {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
        svg {
            fill: currentColor;
        }
    }
    //sideBar__wrapper
</style>