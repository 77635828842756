<template>
    <div>
        <transition name="swipe-left" mode="out-in">
            <v-quick-pay v-if="barPay.value" :selected="barSelected" @hide="SET_BAR({name:'pay', vaue:false})" /> 
        </transition>
        <transition name="swipe-left" mode="out-in">
            <v-transaction v-if="barTrans.value" :selected="barSelected" @hide="SET_BAR({name:'trans', value:false})" />
        </transition>
        <transition name="swipe-left" mode="out-in">
            <v-q-search v-if="barSearch.value" @hide="SET_BAR({name:'search', value:false})" />
        </transition>
        <transition name="swipe-left" mode="out-in">
            <v-chat v-if="barChat.value" :selected="barSelected" @hide="SET_BAR({name:'chat', value:false})" />
        </transition>
        <transition name="swipe-left" mode="out-in">
            <v-company v-if="barCompany.value" @hide="SET_BAR({name:'company', value:false})" />
        </transition>        
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import VQuickPay from './modules/QuickPay'
import vTransaction from './modules/Transaction'
import vQSearch from './modules/QuickSearch.vue'
import vCompany from './modules/CompanySearch.vue'
import vChat from './modules/vChat.vue'
export default {
    name:'vSidebar',
    components:{
        VQuickPay,
        vTransaction,
        vQSearch,
        vChat,
        vCompany
    },
    computed:{
        ...mapState({
            barChat: state => state.sidebar.chat,
            barPay: state => state.sidebar.pay,
            barSearch: state => state.sidebar.search,
            barTrans: state => state.sidebar.trans,
            barCompany: state => state.sidebar.company,
            barSelected: state => state.sidebar.selected,
        })
    },
    methods: {
        ...mapActions([
            'SET_BAR'
        ]),
    },
}
</script>
