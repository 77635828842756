<template >
    <div class="header-quickpay">
        <button 
            class="header-quickpay__button button button_size_small button_color_transp-black button_with_icon"
            :class="{'button_type_active':this.$store.getters.barSearch.value}"
            @click="handlerClick"
            title="Поиск по водителям"
        >
            <div class="header_button_icon header-quickpay__icon">
                <svg aria-hidden="true" width="20" height="20">
                    <use href="#icon-search-2"></use>
                </svg>
            </div>
        </button>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions([
            'SET_BAR',
            'RESET_SIDEBAR'
        ]),

        handlerClick() {
            const value = !this.$store.getters.barSearch.value
            this.RESET_SIDEBAR()
            setTimeout(() => {
                this.SET_BAR({name:'search', value})
            }, 300);
        },
    },
}
</script>
<style lang="scss">
.header-quickpay {
    & &__button {
        margin-right: 0;
    }
}
</style>