

import req from './request'

const DEFAULT_ENDPOINTS = {
    withdraw:'/dashboard/withdraw',
    transaction:'/dashboard/transaction',
    registration:'/dashboard/registration',
    activeDriver :'/dashboard/active-driver',
}

export default {
    
    withdraw(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.withdraw, param)
    },
    transaction(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transaction, param)
    },
    registration(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.registration, param)
    },
    activeDriver(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.activeDriver, param)
    },


}
