import req from './request'

const DEFAULT_ENDPOINTS = {
    upload:'/upload',
}

export default {
    upload(payload, param)  {
        return req.post(payload, DEFAULT_ENDPOINTS.upload, param)
    },
    delete(payload, param)  {
        return req.delete(payload, DEFAULT_ENDPOINTS.upload, param)
    },
}