<template>
    <div class="chat-form">
        <div class="chat-form__row">
            <div class="chat-form__col">
                <v-button 
                    type="button"
                    class="chat-form__button button_type_icon button_type_static"
                    @click="$emit('fastTogle')"
                    :disabled="!template.can_first_send"
                >
                    <svg aria-hidden="true" v-if="!isload" width="24" height="24">
                        <use href="#icon-chat-fast"></use>
                    </svg>
                </v-button>
                <template v-if="isAttach">
                    <v-button 
                        type="button"
                        class="chat-form__button button_type_icon button_type_static"
                        @click="handlFileBtnClick"
                        :disabled="!template.can_first_send"
                    >
                        <svg aria-hidden="true" v-if="!isload" width="24" height="24">
                            <use href="#icon-attach"></use>
                        </svg>
                    </v-button>

                    <input type="file" ref="fileList" :id="'chat_file'" v-show="false" multiple @input="handlFileInput" >
                    <p class="chat-form__text" v-if="files">
                        выбрано: {{files}} 
                        <template v-if="files == 1">
                            файл
                        </template>
                        <template v-else-if="files >=2 && files <= 4">
                            файла
                        </template>
                        <template v-else>
                            файлов
                        </template>
                        для загрузки
                    </p>
                </template>
            </div>
            <div class="chat-form__col">
                <v-message-area
                    ref="messageArea"
                    placeholder="Введите текст"
                    class="size_medium chat-form__textarea"
                    v-model="input"
                    :isload="loaded"
                    @click="handlSend"
                    style="margin-right: 20px;"
                />
                <v-button 
                    type="button"
                    class="chat-form__button button_type_icon button_type_static"
                    :disabled="isTypingNow || !input.length || loaded"
                    :loading="isload"
                    @click="handlSend"
                >
                    <svg aria-hidden="true" v-if="!isload" width="24" height="20">
                        <use href="#icon-send"></use>
                    </svg>
                </v-button>
            </div>
        </div>
    </div>
</template>

<script>
import vMessageArea from '../../global/messageArea.vue'
import vButton from '@components/global/vButton'
// import vChatFast from './chatFast'
export default {
    name: 'vChatForm',
    components: {
        vMessageArea,
        // vChatFast,
        vButton,
    },
    props: {
        template:{
            typeof: Object,
            default: () => {},
        },
        loaded:{
            typeof: Boolean,
            default: false,
        },
        isAttach:{
            typeof: Boolean,
            default: false,
        }
    },    
    data () {
        return {
            input: '',
            search: '',
            files: false,
            isTypingNow: false,
            selectionStart: 0,
            startword: 0,
            isload: false,
        }
    },
    mounted() {
        this.input = this.template && !this.template.can_first_send && this.template.template_text || ''
    },
    methods: {
        handlFileInput() {
            this.files = this.$refs.fileList.files.length
        },
        handlSend() {
            const data = this.getFormData()
            if(this.template && !this.template.can_first_send) {
                this.$emit('submit', data)    
            } else {
                this.$emit('submit', data)
            }

            this.input = ''
            this.$refs.fileList ? this.$refs.fileList.value = '' : ''
            this.files = 0
        },
        setFastPhrase(phrase){
            this.input = phrase
        },

        handlFileBtnClick() {
            this.$refs.fileList.click()
        },
        getFormData(){
            const files = this.$refs.fileList ? this.$refs.fileList.files : null
            const data = {
                files,
                message: this.input
            }
            return data
        }
    }
}
</script>

<style lang="scss">
    .chat-form {
        &__row {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__col {
            display: flex;
            align-items: center;
        }
        & &__button  {
            width: 24px;
            
            // margin-left: 12px;
            // margin-right: 0;
        }
        &__textarea {
            flex: 1 1 100%;
        }
    }
</style>