import api  from '../../api'

export default {
    state: {
        perm: false,
    },
    mutations: {
        SET(state, data) {
            state.perm = data
        },
        RESET(state) {
            state.perm = false
        }
    },
    actions: {
        GET_PERMISSION({commit}) {
            return new Promise((resolv,rej) => {
                api.permission.get().then(res=>{
                    
                    commit('SET', res)
                    resolv(res)
                }).catch(err=>{
                    console.error(err)
                    rej(err)
                })
            })
        },
        RESET_PERMISSION({commit, state}) {
            commit('RESET')
        }
    },
    getters: {
        // perm: state => state.perm
    }
}