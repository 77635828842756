
<template>
   <div class="custom-acomplete"
        :class="{
            //'custom-acomplete_type_error':error,
            'custom-acomplete_type_focus':isFocus,
            'custom-acomplete_type_disabled':disabled
        }"
        
    >
        <label v-if="label" class="custom-acomplete__label" v-html="label">
        </label>
        <div class="custom-acomplete__input" v-click-outside="()=>{handleChanefocuse(false)}"> 
            <v-input 
                :class="inputClass || 'size_large'"
                :icon="icon"
                :buttonIconClass="buttonIconClass"
                :buttonIcon="buttonIcon"
                :value="value.text"
                :placeholder="placeholder"
                @keyup="handlerInput($event)"
                :mask="mask"
                :disabled="false"
                @click="handleInputClick"
                :error="error"
                :isload="isload"
                @focus="handleChanefocuse(true)"
                :iconSize="iconSize"
            />
            
            <v-list 
                class="dropList_size_full-width" 
                :list="list" 
                :expand="isFocus && !disabled && !!list.length" 
                @listButtonClick="$emit('listButtonClick',$event)"
                @click="handleClickList($event)"/>
        </div>
        
        <transition name="grow">
            <div v-if="error" class="validation__label validation__label_type_error">
                {{error}}
            </div>
        </transition>
        <transition name="grow">
            <div v-if="notes" class="validation__label">
                {{notes}}
            </div>
        </transition>
    </div>
</template>

<script>
import vList from './DropDownList' 
import vInput from './CustomInput' 
export default {
    name:"vCustomAutocomplete",
    props: {
        isload: {
            type:Boolean,
            default:false
        },
        buttonIcon: {
            type: String,
            default: ''
        },
        buttonIconClass: {
            type: Object,
            default: () => {}
        },
        inputClass: {
            type: Object,
            default: () => {}
        },
        notes: {
            type: String,
            default: ''
        }, 
        placeholder: {
            type: String,
            default: ''
        },
        name:  {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: [String,Boolean],
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        },
        value:{
            default: ''
        },
        disabled:{
            type: Boolean,
            default: false
        },
        mask: {
            type: Object,
            default: () => {}
        },
        icon: {
            type: String,
            default: ''
        },
        iconSize: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        vList,
        vInput
    },
    data() {
        return {
            isFocus:false,
        }
    },
    methods: {
        handleClickList(event) {
            this.$emit('input', event)
            this.$emit('update', event)
            this.handleChanefocuse(false)
        },
        handleInputClick() {
            this.isFocus = true
            this.$emit('toggleFocuse',this.isFocus)
        },
        handlerInput(event) {
            if(!this.list.length){
                this.$emit('input', {id:null, text:event})
                return
            }
            let newItem = null
            this.list.some(item => {
                const isinList = item.text == event
                if(isinList) {
                    newItem = item
                }
                return isinList
            });
            if(newItem) { 
                this.$emit('input', newItem)
            } else {
                this.$emit('input', {id:null, text:event})
            }
        },
        handleChanefocuse(value) {
            this.isFocus=(value && !this.$props.disabled)
            this.$emit('toggleFocuse',this.isFocus)
        }
    },
    computed: {
        selected() {
            if(!this.value) return this.value
            let selected = ''
            
            this.list.some(item=>{
                if(item.id == this.value){
                    selected = item.name
                    return true
                }
            })
            return selected
        }
    }
}
</script>

<style lang="scss">
    .custom-acomplete {
        width: 100%;
        .dropList_type_pull-top {
            margin-top: 0;
        }
        &__input {
            position: relative;

            .validation__label {
                display: none;
            }
        }

        &__button {
            display: flex;
            border: none;
            padding: 0;
            margin: 0;
            flex-grow: 0;
            margin-left: 8px;
            background-color: transparent;
            color: inherit;
            
            
            svg {
                fill: currentColor;
                flex-shrink: 0;
                margin-right: 8px;
                transition: .3s;
                transform: scale(1);
            }
            &.is-active svg{
               transform: scale(-1);
            }
        }


        &__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #919197;
            margin-bottom: 8px;
            display: inline-block;
        }

        &_type {
            &_focus .custom-acomplete__input {
                border-color:#3A4146;
            }
            // &_error .custom-acomplete__input {
            //     border-color: #E94A4A
            // }
            &_disabled .custom-acomplete__input {
                background: #FAFAFA;
            }
        }

        &.size {

            &_small .custom-acomplete__input {
                min-height: 32px;
            }

            &_medium .custom-acomplete__input {
                min-height: 40px;
            }

            &_large .custom-acomplete__input {
                min-height: 56px;
            }
        }

        &.on-focus {
            border-color: #3A4146;
        }

        &.disabled {
            background: #FAFAFA;
        }
    }
</style>