<template>
    <div class="chat-message" :id="'message-'+message.id"
        :class="{
            'chat-message__type_system':message.type == 'system',
            'chat-message__type_answer':message.type == 'answer' 
        }"
    >
        <span v-if="message.type == 'system'" v-html="message.message"></span>
        <template v-else>
            <div class="chat-message__img" v-if="message.type == 'message'">
                <img :src="img" alt="">
            </div>
            <div class="chat-message__content" :class="{'chat-message__content-answer':message.type == 'answer'}">
                <div class="chat-message__head" v-if="message.responsible_user != $store.getters.user.name">
                    {{ message.responsible_user }}
                </div>
                <span ref="htmlMessage" v-html="tMessage"></span>
                <div class="chat-message__footer">
                    <svg v-if="message.is_send && message.is_read " aria-hidden="true" width="12" height="12">
                        <use href="#icon-double-check"></use>
                    </svg>
                    <svg v-else-if="message.is_send || message.is_read " aria-hidden="true" width="12" height="12">
                        <use href="#icon-checked"></use>
                    </svg>
                    {{message.date_created}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from '@api'
export default {
    props: {
        message: '',
        img: null
    },
    
    data () {
        return {
            tMessage:''
        }
    },
    mounted() {
        if(this.message.fromSocket) this.sendReadStatus()
        
        this.tMessage = this.replaceNWithBr(this.message.message)
        this.$nextTick(() => {
            const $htmlMessage = this.$refs.htmlMessage
            if(!$htmlMessage) {
                return
            }
            const img = $htmlMessage.querySelector('img')
            if(img) {
                img.onload = () =>{
                    this.onImgLoad(this.message)
                }
            }
        })
    },
    methods: {
        replaceNWithBr(str) {
            return str? str.replace(/\n/g, '<br>') : '';
        },
        sendReadStatus() {
            const message = this.message
            const payload = {
                id: message.id
            }
            return api.chat.messaageUpdate(payload,'','put').then(response=>{
                this.message.is_read = true
            }).catch(error=>{
                this.$toast.error(error.message)
            })
        },
        onImgLoad(message) {
            this.$emit('imgload', {message, is:message.responsible_user != this.$store.getters.user.name})
        }
    }
}
</script>

<style lang="scss">
@import '../../../../../gulp-src/app/scss/mixins';

.chat-message{
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    
    &:last-child {
        padding-bottom: 16px;
    }
    &__type { 
        &_system {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #919197;
            justify-content: center;
            align-items: center;
            &:before, &:after {
                display: block;
                content: '';
                flex: 1 1 auto;
                height: 1px;
                background-color: #CBCCCF;
                margin-right: 8px;
            }
            &::after {
                margin-left: 8px;
                margin-right: 0;
            }
        }

        &_answer {
            justify-content: flex-end;
        }
    }

    &__img {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 32px;
        height: 32px;

        img {
            width: 100%;
            height: 100%;
        }

    }

    &__content { 
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #E5E9EB;
        border-radius: 12px;
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0A0A17;
        overflow: auto;
        max-width: 100%;
        @include scrollbar();
        img{ max-width: 90%;}
        
    }
    

    &__content-answer {
        background-color: #3A4146;
        color: #fff;
    }

    .active &__content,
    .active &__content-answer {
        background: #F9C43E;
    }

    &__type_system.active  {
        background: #F9C43E;
    }

    &__footer, &__head {
        margin-top: 8px;
        display: flex;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 16px;
        color: #919197;
        align-items: center;
        svg { 
            fill: currentColor;
            margin-right: 8px;
        }
    }

    &__head { 
        margin-top: 0;
        margin-bottom: 8px;
        
    }
}
</style>