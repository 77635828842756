import req from './request'

const DEFAULT_ENDPOINTS = {
    base: '/cards/base',
    fraudulent: '/cards/fraudulent',
    top: '/cards/top',
    topDetail: '/cards/top/detail'
}

export default {
    base(payload,param,method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.base, param)
    },
    fraudulent(payload,param,method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.fraudulent, param)
    },
    top(payload,param,method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.top, param)
    },
    topDetail(payload,param,method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.topDetail, param)
    }
}