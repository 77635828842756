<template>
    <div>
        <v-modal v-if="!!show" :show="true" @close="closeModal">
            <p slot='header'> Вызов завершен </p>
            <div slot="body" class="buttons_list">
                <button class="button button_size_ex-large button_color_black" @click='clickButton(btn)' v-for="btn in buttons">
                    {{ btn.name }}
                </button>
            </div>
            <!-- <div slot="footer" class="flex-right">
                <button class="button button_size_ex-large button_color_white" @click='handleReset'>Закрыть</button>
                <button class="button button_size_ex-large button_color_black" @click="handleApply(call)">Перейти</button>
            </div>  -->
        </v-modal>
    </div>
</template>

<script>
import vModal from '@components/global/Modal';
import api from '@api';
export default {
    name: 'EndCallModal',
    props:{
        uuid: {
            type: String,
            default: ''
        }
    },
    components: {
        vModal
    },
    data(){
        return {
            show: false,
            buttons: false
        }
    },
    mounted() {
        this.getRef().then(res=>{
            this.buttons = res.category_list
            this.show = true
        })
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        getRef() {
            return api.chat.endcallRefference().then(response=>{
                return response
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        },
        post(category_id) {
            const paylaod = {uuid:this.uuid, category_id}
            return api.chat.chooseCategory(paylaod,'','post').then(response=>{
                return response
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        },
        clickButton(button) {
            this.post(button.id).then(response=>{
                this.$toast.success(response.message)
                this.closeModal()
            })
        }
    }
}
</script>

<style lang="scss">
    .buttons_list{
        display: block;
        min-width: 500px;
        .button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
</style>