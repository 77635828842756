<template>
    <div class="header-chat">
        <button class="header-chat__button button button_size_small button_color_transp-black button_with_icon"
        :class="{'button_type_active':this.$store.getters.barChat.value}"
        title="Чат"
        @click="handlerClick">
            <div class="header_button_icon header-chat__icon" ref="icon">
                <span v-if="count" class="header-chat__button-counter">
                    <template v-if="count > 99">
                        99+
                    </template>
                    <template v-if="count<100">
                        {{ count }}
                    </template>
                </span>
                <svg aria-hidden="true" width="20" height="20" >
                    <use href="#icon-chat"></use>
                </svg>
            </div>
            <!-- <span class="header-chat__text">
                Чат
            </span> -->
        </button>
    </div>
</template>

<script>
import { eventBus } from '@eventBus'
import { mapActions } from 'vuex'
import api from '@api'
export default { 
    name: 'HeaderChatNotification',
    data() {
        return {
            count: 0,
            animationInProgress: false
        }
    },
    mounted() {
        this.getConters().then(response=>{
            if(!response) return
            this.count = response.total_unread_my_messages
            if(this.count){
                setTimeout(() => {
                    this.newMessageIncoming()    
                }, 1000);
            }
        })
        eventBus.$on('socketUpdateCounter', this.handleUpdateCounter);
    },
    methods: {
        ...mapActions([
            'SET_BAR',
            'RESET_SIDEBAR'
        ]),
        handlerClick() {
            const value = !this.$store.getters.barChat.value
            this.RESET_SIDEBAR()
            setTimeout(() => {
                this.SET_BAR({name:'chat', value})
            }, 300);
        },
        handleUpdateCounter(data) {
            const temp = this.count
            this.count = data.total_unread_my_message
            if(data.is_my_chat && temp < this.count) this.newMessageIncoming()
        },
        newMessageIncoming() {
            if(this.animationInProgress) return
            const icon = this.$refs.icon
            const newClass = 'header-chat__icon-new'
            icon.classList.add(newClass)
            
            setTimeout(() => {
                this.animationInProgress = false
                icon.classList.remove(newClass)
            }, 1000);

            const sound = new Audio('/sound/new_message.mp3');
            sound.play();
        },

        getConters() {
            this.isloading = true
            return api.chat.getCounts().then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
    },
}
</script>

<style lang="scss">
@import '../../../../../gulp-src/app/scss/_variables';
.header-chat {
    &__button {
        background-color: transparent;
        position: relative;
        &-counter {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            height: 20px;
            position: absolute;
            top: -12px;
            right: 0;
            padding: 3px;
            font-size: 12px;
            background-color: $global-rage;
            border-radius: 14px;
            border: 2px solid $global-white;
            color: $global-white;
        }
    }

    &__text {
        color:#0A0A17;
    }

    &__icon{
        position: relative;
    }

    &__icon-new svg {
        animation: bounceRotateAnimation 0.5s ease-in-out 2;
    }
}


@keyframes bounceRotateAnimation {
  0%, 100% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(45deg) scale(1.15);
    color: #e94a4a;
  }
}
</style>