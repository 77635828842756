<template>
    <router-link class="navbar-link__button button button_with_icon button_size_large"
        :class="{
            'button_color_gray-84':$route.meta.activeSideIcon != item.icon,
            'button_color_yellow':$route.meta.activeSideIcon == item.icon
        }"
        :to="item.href"
        v-if="item.label.toLowerCase().includes( search.toLowerCase() )"
        @click.native="$emit('routeClick')"
    >
        <svg class="navbar-link__svg" aria-hidden=true width="20" height="20">
            <use :href="item.icon"></use>
        </svg>
        <transition name="expand">
            <div class="navbar-link__text" v-if="expand">
                <span> {{item.label}} </span>
            </div>
        </transition>
    </router-link>
</template>

<script>
export default {
    name: 'NavLink',
    props: {
        search: {
            type: String,
            default: ''
        },
        expand: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss">
.navbar-nav__item .navbar-link {
    &__button {
        height: 50px;
        padding: 14px;
        display: flex;
        border-radius: 6px;
        justify-content: flex-start;
        text-align: left;
        span {
            overflow: visible;
        }
    }

    &__svg {
        margin-left: 0;
        margin-right: 0;
    }
    
    &__text {
        overflow: hidden;
        display: inline-block;

        max-width: 180px;
        opacity: 1;

        span {
            padding-left: 15px;
            display: inline-block;
            width: 180px;
        }
    }
}
</style>